<template>
  <div class="">
    <div class="flex justify-between items-center mb-6 border-b pb-6">
      <div>
        <h1 class="text-4xl">Create new branch</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="flex flex-col">
      <form class="needs-validation" @submit.prevent="onSubmit">
        <div class="flex flex-wrap overflow-hidden">
          <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Branch Name
              </label>
              <input
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                name="name"
                v-model="form.name"
                type="text"
                :class="{
                  'is-invalid': form.errors.has('name'),
                }"
                required
                placeholder="Enter branch name"
              />
            </div>
          </div>

          <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Branch Address
              </label>
              <input
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                name="name"
                v-model="form.address"
                type="text"
                :class="{
                  'is-invalid': form.errors.has('address'),
                }"
                required
                placeholder="Enter branch address"
              />
            </div>
          </div>

          <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Branch Contact Phone
              </label>
              <input
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                name="name"
                v-model="form.contact_phone"
                type="text"
                :class="{
                  'is-invalid': form.errors.has('contact_phone'),
                }"
                required
                placeholder="Enter branch contact phone"
              />
            </div>
          </div>

          <div></div>
        </div>
        <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
          <!-- Column Content -->
          <button
            class="
              px-4
              py-2
              rounded
              text-white
              inline-block
              shadow-lg
              bg-blue-500
              hover:bg-blue-600
              focus:bg-blue-700
            "
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Form from "vform";

import { mapGetters } from "vuex";
import checkPermissionExist from "../../mixins/checkPermissionExist";

// import BranchSales from "../../components/Charts/BranchSales";

export default {
  middleware: "auth",

  layout: "default",
  components: {
    // BranchSales,
    // HelloWorld
  },

  mixins: [checkPermissionExist],
  computed: mapGetters({
    user: "auth/user",
  }),

  created() {
    if (!this.checkPermissionExist(this.user.data, "branchs-create")) {
      this.$router.go(-1);
      return false;
    }
  },

  data: () => ({
    isLoading: false,

    form: new Form({
      name: "",
      address: "",
      contact_phone: "",
    }),
  }),

  methods: {
    async onSubmit() {
      // Submit the form
      this.isLoading = true;

      try {
        await this.form.post("/branch/store");

        this.isLoading = false;
        this.$router.push({ path: "/branches" });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },
  },
};
</script>